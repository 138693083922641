<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Category
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Category Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="currentCategory.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Category Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
     
 
          <category-selector :initial-id="currentCategory==null||currentCategory.upperCategory==null?null:currentCategory.upperCategory"  @selectionChanged="selectionChanged" ></category-selector>
       

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             {{ currentCategory==null|| currentCategory.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="currentCategory!=null&& currentCategory.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import CategorySelector from '../components/SingleCategorySelector.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    CategorySelector
  },
   methods:{
      selectionChanged(selectedGroup){
       // console.log(selectedGroup);

       if (selectedGroup==null||selectedGroup==undefined)
       {
   this.currentCategory.upperCategory = null;
       }else
       {
   this.currentCategory.upperCategory = selectedGroup.id
       }
   
    },
    deletecategory(){
      this.currentCategory.deleted=true;

       this.$store.dispatch('app-course-category/addCategory', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm(){
       this.$store.dispatch('app-course-category/addCategory', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },
  
  watch: {
     categorydata:   {
        handler:function (n, o)   {
          console.log('new client: ', n);

                  if (n==null||n==undefined)
                  {
                    this.currentCategory={
                      id:null,
                      name:null,
                      upperCategory:null
                    }
                  } else
                  { 
                    console.log('new client: ', n.selectedCategory);
                  this.currentCategory = n.selectedCategory;
                  }
        

        },
        deep: true
    }
    
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentCategory:{
        id:null,
        name:null,
        upperCategory:null
      }
    }
  },
   
  inject: ['categorydata'],

  setup(props,  { emit }) {

   
    const blankUserData = {
       id:null,
        name:null,
        upperCategory:null
     


    }

     
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
      

    const onSubmit = () => {
      store.dispatch('app-course-category/addCategory', currentCategory)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
     
      

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
